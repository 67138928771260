import tippy from 'tippy.js'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "submitButtonContainer",
    "option",
    "note",
  ];

  static selection

  connect() {
    this.passAlongExistingParams()
    this.updateFormUrl()
  }

  passAlongExistingParams() {
    const search = new URLSearchParams(window.location.search)
    let url = new URL(this.submitButtonTarget.href)
    url.search = search
    this.submitButtonTarget.href = url.toString()
  }

  select(event) {
    sessionStorage.setItem('trialPriority', event.currentTarget.dataset.trialOptionValue)
    this.updateFormUrl()
  }

  enableSubmitButton() {
    this.noteTarget.style.display = ''
    this.submitButtonTarget.removeAttribute("disabled")
    this.submitButtonContainerTarget._tippy && this.submitButtonContainerTarget._tippy.destroy()
  }

  disableSubmitButton() {
    this.noteTarget.style.display = 'none'
    this.submitButtonTarget.setAttribute("disabled", "disabled")
    this.submitButtonContainerTarget._tippy && this.submitButtonContainerTarget._tippy.destroy()
    tippy(this.submitButtonContainerTarget, {
      content: this.submitButtonContainerTarget.getAttribute("data-tippy-text"),
      popperOptions: {
        strategy: "fixed",
      },
    })
  }

  highlightCorrectChoice() {
    this.optionTargets.forEach(target => {
      if (target.dataset.trialOptionValue == sessionStorage.getItem('trialPriority')) {
        target.classList.add("active")
      } else {
        target.classList.remove("active")
      }
    })
  }

  updateFormUrl() {
    this.highlightCorrectChoice()
    if (sessionStorage.getItem('trialPriority')) {
      let url = new URL(this.submitButtonTarget.href)
      url.searchParams.set('priority', sessionStorage.getItem('trialPriority'))
      this.submitButtonTarget.href = url.toString()
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }
}
